<template>
  <div class="app-container">
    <el-card>
      <student-record></student-record>
    </el-card>
  </div>
</template>


<!-- 单个文件引用 -->
<style scoped>
  @import '../../static/css/lightbox.css';
</style>
<script scoped>
  import '../../static/js/lightbox-plus-jquery.min.js'
</script>

<script>
import StudentRecord from "./components/StudentRecord.vue";
export default {
  components: {
    StudentRecord,
  },
  data() {
    return {
      activeName: "student",
    };
  },
  methods: {
    handleClick(val) {
      console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-tabs__item.is-active {
  font-weight: bold;
  font-size: 15px;
}
/deep/ .el-container {
  width: 100%;
  height: 100% !important;
}
/deep/.el-dialog__title {
  display: flex;
  flex-direction: row;
}
/deep/.el-dialog__body {
  padding: 0px;
  text-align: center;
}
</style>